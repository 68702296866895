import React from "react"
import cx from "classnames"
import loadable from "@loadable/component"

import { IMG } from "../const"
import { TECHNOLOGIES, GAMES_LIST } from "../data/blue-manor-academy"
import Layout from "../components/layout"
import "../assets/styles/commons/lists/_common__list__techLogos-rounded.scss"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/bma.module.scss"
import JobProcess from "../components/blue-manor-academy/job-process"
import RequestScheme from "../components/blue-manor-academy/requests-scheme"
import PlatformFeatures from "../components/blue-manor-academy/platform-features"
import Text from "../components/blue-manor-academy/text"

const ContactForm = loadable(() => import("../components/contact-form"))

const BlueManorAcademy = () => (
  <Layout pageTitle="Blue Manor Academy">
    {({
      width,
      handleOpenConfirmation,
      isOpenConfirmation,
      setConfirmationOption,
    }) => {
      return (
        <main className={cx(styles.bmaBlock, "common-kit")}>
          <article>
            <div className={styles.heading}>
              <h1>
                An elite education platform, at a price every parent can afford
              </h1>
            </div>
            <div className={styles.technologiesBlock}>
              <ul className={styles.details}>
                <li>
                  <dl>
                    <dt>Industry:</dt>
                    <dd>EdTech</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>Country:</dt>
                    <dd>USA</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>Company:</dt>
                    <dd>Blue Manor Academy</dd>
                  </dl>
                </li>
              </ul>

              <ul className="common__list__techLogos-rounded">
                {TECHNOLOGIES.map(technology => (
                  <li key={technology.name}>
                    <figure>
                      <img
                        src={`${IMG.ICONS}/logos/${technology.icon}`}
                        width="32"
                        height="32"
                        alt={technology.name}
                      />
                      <figcaption>{technology.name}</figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </div>
            <section className={"section"}>
              <div className={styles.feedback}>
                <figure>
                  <picture>
                    <source
                      srcSet={`${IMG.BMA}/Britton-LaTulippe.avif,  ${IMG.BMA}/Britton-LaTulippe@2x.avif 2x, ${IMG.BMA}/Britton-LaTulippe@3x.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      srcSet={`${IMG.BMA}/Britton-LaTulippe.webp,  ${IMG.BMA}/Britton-LaTulippe@2x.webp 2x, ${IMG.BMA}/Britton-LaTulippe@3x.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      srcSet={`${IMG.BMA}/Britton-LaTulippe.jpg,   ${IMG.BMA}/Britton-LaTulippe@2x.jpg 2x,  ${IMG.BMA}/Britton-LaTulippe@3x.jpg 3x`}
                    />
                    <img
                      src={`${IMG.BMA}/Britton-LaTulippe.jpg`}
                      alt="Britton LaTulippe"
                      width="150"
                      height="150"
                      loading="lazy"
                    />
                  </picture>
                  <div>
                    <blockquote>
                      “In many ways, LaSoft saved our company. Our online
                      academy failed to grow for several years, and we thought
                      it was just our curriculum. We kept improving the
                      curriculum but didn't see an increase in subscriptions.
                      Finally, we hired LaSoft, and they redesigned our checkout
                      system and main dashboard. We immediately saw an increase
                      in subscribers. Now, we have been working with LaSoft for
                      three years and could not be happier!”
                    </blockquote>
                    <figcaption itemScope itemType="https://schema.org/Person">
                      <strong itemProp="name">Britton LaTulippe</strong>
                      <span itemProp="jobTitle">
                        President of Blue Manor Academy
                      </span>
                    </figcaption>
                  </div>
                </figure>
              </div>
            </section>

            <section className={cx(styles.mainImgWrapper, "section")}>
              <div className={styles.mainImg}>
                <picture>
                  <source
                    media="(max-width: 480px)"
                    srcSet={`${IMG.BMA}/bma-main-mobile.avif,  ${IMG.BMA}/bma-main-mobile@2x.avif 2x, ${IMG.BMA}/bma-main-mobile@3x.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(max-width: 480px)"
                    srcSet={`${IMG.BMA}/bma-main-mobile.webp,  ${IMG.BMA}/bma-main-mobile@2x.webp 2x, ${IMG.BMA}/bma-main-mobile@3x.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(max-width: 480px)"
                    srcSet={`${IMG.BMA}/bma-main-mobile.jpg,  ${IMG.BMA}/bma-main-mobile@2x.jpg 2x, ${IMG.BMA}/bma-main-mobile@3x.jpg 3x`}
                  />

                  <source
                    media="(min-width: 481px and max-width: 1024px)"
                    srcSet={`${IMG.BMA}/bma-main-tablet.avif,  ${IMG.BMA}/bma-main-tablet@2x.avif 2x, ${IMG.BMA}/bma-main-tablet@3x.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 481px and max-width: 1024px)"
                    srcSet={`${IMG.BMA}/bma-main-tablet.webp,  ${IMG.BMA}/bma-main-tablet@2x.webp 2x, ${IMG.BMA}/bma-main-tablet@3x.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 481px and max-width: 1024px)"
                    srcSet={`${IMG.BMA}/bma-main-tablet.jpg,  ${IMG.BMA}/bma-main-tablet@2x.jpg 2x, ${IMG.BMA}/bma-main-tablet@3x.jpg 3x`}
                  />

                  <source
                    media="(min-width: 1025px)"
                    srcSet={`${IMG.BMA}/bma-main.avif,  ${IMG.BMA}/bma-main@2x.avif 2x, ${IMG.BMA}/bma-main@3x.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 1025px)"
                    srcSet={`${IMG.BMA}/bma-main.webp,  ${IMG.BMA}/bma-main@2x.webp 2x, ${IMG.BMA}/bma-main@3x.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 1025px)"
                    srcSet={`${IMG.BMA}/bma-main.jpg,  ${IMG.BMA}/bma-main@2x.jpg 2x, ${IMG.BMA}/bma-main@3x.jpg 3x`}
                  />
                  <img
                    src={`${IMG.BMA}/bma-main.jpg`}
                    alt="Child is studding with Blue Manor Academy"
                    width="1210"
                    height="808"
                    loading="lazy"
                  />
                </picture>
              </div>
            </section>

            <section className="section">
              <div className={styles.listsBlock}>
                <ul className={styles.mainList}>
                  <li>
                    <h2>Client & Product</h2>
                    <div>
                      <div className={styles.mainList__column}>
                        <p>
                          <strong>
                            Blue Manor Academy is an interactive educational web
                            platform for homeschooling preschoolers, elementary,
                            and middle school students. It operates on a
                            subscription-based model.
                          </strong>
                        </p>
                        <p>
                          Our client, Britton, is the founder and the director
                          of BMA. As a father of multiple children, he sought a
                          comprehensive solution to enhance their preschool
                          education and boost their learning motivation.
                          Additionally, Britton aimed to address the issue of
                          students lacking the drive to engage in their studies.
                          Often, parents cannot afford the best educational
                          institutions, and teachers may lack motivation due to
                          inadequate compensation.
                        </p>
                      </div>

                      <div className={styles.mainList__column}>
                        <p>
                          In light of these challenges, Britton began creating
                          BMA. This platform aims to assist parents in managing
                          their children's education effectively while fostering
                          a higher level of self-motivation among students
                          through an interactive learning format.
                        </p>
                        <div className={styles.img}>
                          <picture>
                            <source
                              media="(max-width: 900px)"
                              srcSet={`${IMG.BMA}/clietn-product-mobile.avif,  ${IMG.BMA}/clietn-product-mobile@2x.avif 2x, ${IMG.BMA}/clietn-product-mobile@3x.avif 3x`}
                              type="image/avif"
                            />
                            <source
                              media="(max-width: 900px)"
                              srcSet={`${IMG.BMA}/clietn-product-mobile.webp,  ${IMG.BMA}/clietn-product-mobile@2x.webp 2x, ${IMG.BMA}/clietn-product-mobile@3x.webp 3x`}
                              type="image/webp"
                            />
                            <source
                              media="(max-width: 900px)"
                              srcSet={`${IMG.BMA}/clietn-product-mobile.jpg,  ${IMG.BMA}/clietn-product-mobile@2x.jpg 2x, ${IMG.BMA}/clietn-product-mobile@3x.jpg 3x`}
                            />

                            <source
                              media="(min-width: 901px)"
                              srcSet={`${IMG.BMA}/clietn-product.avif,  ${IMG.BMA}/clietn-product@2x.avif 2x, ${IMG.BMA}/clietn-product@3x.avif 3x`}
                              type="image/avif"
                            />
                            <source
                              media="(min-width: 901px)"
                              srcSet={`${IMG.BMA}/clietn-product.webp,  ${IMG.BMA}/clietn-product@2x.webp 2x, ${IMG.BMA}/clietn-product@3x.webp 3x`}
                              type="image/webp"
                            />
                            <source
                              media="(min-width: 901px)"
                              srcSet={`${IMG.BMA}/clietn-product.jpg,  ${IMG.BMA}/clietn-product@2x.jpg 2x, ${IMG.BMA}/clietn-product@3x.jpg 3x`}
                            />
                            <img
                              src={`${IMG.BMA}/clietn-product.jpg`}
                              alt="Cheerful family"
                              width="570"
                              height="340"
                              loading="lazy"
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </li>

                  {/* -- 2 -- */}
                  <li>
                    <div>
                      <div className={styles.mainList__column}>
                        <h2>Request</h2>
                        <p>
                          Briton expressed dissatisfaction with the code quality
                          and performance of the web platform following their
                          engagement with a previous vendor.
                        </p>
                        <p>
                          The code was filled with temporary solutions that
                          didn't work well, slowed down the page speed several
                          times, and, most importantly, made it impossible to
                          scale the product.
                        </p>
                        <p>
                          Because of this, they reached out to LaSoft. Our
                          collaboration began with a focus on enhancing the
                          technical aspects: conducting a code review,
                          performing refactoring, and updating data libraries.
                          We undertook the migration from a monolithic
                          application to microservices and this transformation
                          rendered the platform well-equipped for future
                          expansion.
                        </p>
                        {width >= 901 && <Text />}
                      </div>
                      <div className={styles.mainList__column}>
                        <div className={styles.img}>
                          <img
                            src={`${IMG.BMA}/request.svg`}
                            className={styles.schema}
                            alt="Schema"
                            width="554"
                            height="554"
                            loading="lazy"
                          />
                        </div>
                      </div>
                      {width < 901 && <Text />}
                    </div>
                    <RequestScheme />
                  </li>

                  {/* -- 3 -- */}
                  <li>
                    <h2>How was the job done?</h2>
                    <p>
                      Our highly qualified team took the system to the next
                      level and developed all new features following the
                      engagement process.
                    </p>
                    <JobProcess />
                  </li>

                  {/* -- 4 -- */}
                  <li>
                    <h2>Platform Features</h2>
                    <p>
                      We have developed the main functionality into one concise
                      web platform.
                    </p>
                    <PlatformFeatures />
                    <div>
                      <p className={styles.largeText}>
                        Our platform features a user-friendly library, offering
                        a vast collection of books categorized by grade level,
                        literary genres, and authors. This organization
                        facilitates quick and easy access to the desired
                        resources, supporting a seamless learning journey.
                      </p>
                    </div>
                  </li>

                  {/* -- 5 -- */}
                  <li>
                    <h2>Gamification of Learning</h2>
                    <p>
                      We developed gamification within the training modules to
                      improve the results of users' knowledge acquisition.
                    </p>
                    <div className={styles.threeImgs}>
                      <picture>
                        <source
                          srcSet={`${IMG.BMA}/student-dashboard-clubs.avif,  ${IMG.BMA}/student-dashboard-clubs@2x.avif 2x, ${IMG.BMA}/student-dashboard-clubs@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.BMA}/student-dashboard-clubs.webp,  ${IMG.BMA}/student-dashboard-clubs@2x.webp 2x, ${IMG.BMA}/student-dashboard-clubs@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.BMA}/student-dashboard-clubs.png,  ${IMG.BMA}/student-dashboard-clubs@2x.png 2x, ${IMG.BMA}/student-dashboard-clubs@3x.png 3x`}
                          type="image/png"
                        />
                        <img
                          src={`${IMG.BMA}/student-dashboard-clubs.png`}
                          alt=""
                          width="386"
                          height="282"
                          loading="lazy"
                        />
                      </picture>
                      <picture>
                        <source
                          srcSet={`${IMG.BMA}/student-dashboard-memory.avif,  ${IMG.BMA}/student-dashboard-memory@2x.avif 2x, ${IMG.BMA}/student-dashboard-memory@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.BMA}/student-dashboard-memory.webp,  ${IMG.BMA}/student-dashboard-memory@2x.webp 2x, ${IMG.BMA}/student-dashboard-memory@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.BMA}/student-dashboard-memory.png,  ${IMG.BMA}/student-dashboard-memory@2x.png 2x, ${IMG.BMA}/student-dashboard-memory@3x.png 3x`}
                          type="image/png"
                        />
                        <img
                          src={`${IMG.BMA}/student-dashboard-memory.png`}
                          alt=""
                          width="498"
                          height="282"
                          loading="lazy"
                        />
                      </picture>
                      <picture>
                        <source
                          srcSet={`${IMG.BMA}/student-dashboard-learn.avif,  ${IMG.BMA}/student-dashboard-learn@2x.avif 2x, ${IMG.BMA}/student-dashboard-learn@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.BMA}/student-dashboard-learn.webp,  ${IMG.BMA}/student-dashboard-learn@2x.webp 2x, ${IMG.BMA}/student-dashboard-learn@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.BMA}/student-dashboard-learn.png,  ${IMG.BMA}/student-dashboard-learn@2x.png 2x, ${IMG.BMA}/student-dashboard-learn@3x.png 3x`}
                          type="image/png"
                        />
                        <img
                          src={`${IMG.BMA}/student-dashboard-learn.png`}
                          alt=""
                          width="570"
                          height="407"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <ul className={styles.cardsList}>
                      {GAMES_LIST.map(item => (
                        <li key={item.title} className={styles.bgColor}>
                          <h4>{item.title}</h4>
                          <p>{item.text}</p>
                        </li>
                      ))}
                    </ul>
                    <div>
                      <p className={styles.largeText}>
                        These gamified elements aim to make the learning process
                        more engaging, enjoyable, and practical, providing users
                        with opportunities to enhance their cognitive abilities
                        and acquire knowledge in a fun and interactive manner.
                      </p>
                    </div>
                    <div>
                      <h2 className={styles.wide}>Outcomes</h2>
                      <div className={styles.mainList__column}>
                        <p>
                          Today, the Blue Manor Academy platform remains in a
                          state of continuous evolution, consistently expanding
                          its content offerings. During the COVID-19 lockdown,
                          the web application experienced a substantial surge in
                          popularity. With current data indicating that
                          approximately 9-11% of all students in the United
                          States are enrolled in online schools, Blue Manor
                          Academy finds itself positioned in a large market with
                          a significant potential user base.
                        </p>
                      </div>
                      <div className={styles.mainList__column}>
                        <p className={cx(styles.bgColor, styles.strong)}>
                          <strong>
                            Blue Manor Academy boasts several hundred active
                            users, demonstrating the platform's growing
                            influence and success in providing a valuable
                            educational resource to students and parents alike.
                          </strong>
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </article>
          <section className="footerForm common-kit">
            <div className="section">
              <ContactForm
                formStatID="CommonFooter"
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </div>
          </section>
        </main>
      )
    }}
  </Layout>
)

export default BlueManorAcademy
